<template>
  <app-modal
    :value="value"
    :header-text="headerText"
    :persistent="true"
    width="600px"
  >
    <template #subheader>
      <v-alert
        type="warning"
        text
      >
        {{ warningText }}
      </v-alert>
    </template>

    <template #content>
      <v-text-field
        v-model="confirmationText"
        :label="`Type '${ actionWord.toLocaleUpperCase()}' to confirm`"
        filled
      />
    </template>

    <template #action>
      <v-spacer/>
      <v-btn
        @click="cancel()"
        depressed
      >
        Cancel
      </v-btn>
      <v-btn
        @click="actionItem()"
        :loading="loading"
        class="ml-2"
        color="primary"
        depressed
        :disabled="!actionIsConfirmed"
      >
        {{ actionWord.charAt(0).toLocaleUpperCase() + actionWord.substr(1).toLocaleLowerCase() }}
      </v-btn>
    </template>
  </app-modal>
</template>

<script>
import AppModal from "@/components/app/AppModal"

export default {
  name: "AppActionModal",

  components: {
    AppModal,
  },

  props: {
    value: {
      type: Boolean,
      required: true,
    },
    headerText: {
      type: String,
      required: true,
    },
    actionWord: {
      type: String,
      required: false,
      default: "delete",
    },
    warningText: {
      type: String,
      required: false,
      default: "",
    },
    loading: {
      type: Boolean,
      required: false,
      defalt: false,
    }
  },

  data: () => ({
    confirmationText: null,
  }),

  computed: {
    actionIsConfirmed() {
      return this.confirmationText != null
        && this.confirmationText == this.actionWord.toLocaleUpperCase()
    }
  },

  methods: {
    cancel() {
      this.confirmationText = null
      this.$emit('cancel')
    },

    actionItem() {
      this.confirmationText = null
      this.$emit('action')
    }
  },
}
</script>

<style>

</style>